<template>
  <div :id="elId" v-editable="blok" class="w-full">
    <client-only>
      <Vue3Lottie
        :id="id"
        class="lottie"
        :animation-link="
          blok.lottie.filename.replace(
            'https://a.storyblok.com',
            'https://a2.storyblok.com',
          )
        "
        :height="`${blok.height}px`"
        :width="`${blok.width}px`"
        :loop="blok?.loop"
        :auto-play="!lottie.pauseAnimation"
        :pause-animation="lottie.pauseAnimation"
        :scale="blok.scale ? blok.scale : 1"
        :speed="blok.speed ? blok.speed : 1"
      />
      <p
        v-if="blok.text"
        class="text-gray-400 text-sm max-w-[80%] sm:max-w-[70%] lg:max-w-[60%] mx-auto text-center"
      >
        {{ blok.text }}
      </p>
    </client-only>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
  imageLayoutClasses: {
    type: Object,
    default: () => null,
  },
});
const id = props.blok._uid;
const elId = `lottie-${id}`;
const lottie = reactive({ pauseAnimation: true });

function setWrapperContainerSize() {
  const el = document.querySelector(`#${elId}`);
  if (!el) return;

  const svg = el.getElementsByTagName("svg")[0];
  el.style.height = `${svg.getBoundingClientRect().height}px`;
  svg.classList.add("position-absolute");
}

onMounted(() => {
  setTimeout(() => {
    // init controller
    const controller = new ScrollMagic.Controller({ container: "body" });

    // create a scene
    const scene = new ScrollMagic.Scene({
      triggerElement: `#${elId}`,
      offset: -100, // start this scene after scrolling for 50px
    })
      .on("start", function (ev) {
        lottie.pauseAnimation = false;
      })
      .addTo(controller); // assign the scene to the controller

    setWrapperContainerSize();

    window.addEventListener("resize", () => {
      setWrapperContainerSize();
    });
  }, 500);

  return { lottie };
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    setWrapperContainerSize();
  });
});
</script>

<style lang="scss">
.lottie {
  overflow: visible;
  max-width: 100%;
  height: 100%;
  position: relative;
}
.lottie-animation-container svg {
}
</style>
